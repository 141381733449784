import React from "react";
import {Thumbnail} from "@shopify/polaris";


export const Placeholder = ({children, showBorder = false}) => {
    return (
        <div style={{
            borderInlineStart: showBorder ? '2px solid var(--p-color-border)' : 'none',
            padding: '0em 2em',
            width: 'auto',
        }}>
            {children}
        </div>
    );
};

export function productThumbnail(url, title) {
    return (
        <Thumbnail
            source={url}
            alt={title}
        />
    );
}

export const handleMouseMoveZoom = (e) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    const zoom = 2;
    e.target.style.transform = `scale(${zoom})`;
    e.target.style.transformOrigin = `${x}px ${y}px`;
    e.target.style.zIndex = '10000 !important';
    // e.target.style.position = 'absolute';
    // e.target.style.width = '150px';

}

export const handleMouseOutZoom = (e) => {
    e.target.style.transform = `scale(1)`;
    e.target.style.zIndex = 0;
    // e.target.style.position = 'relative';
    // e.target.style.width = '100px';

}

export const formatDate = (dateString) => {
    const options = {year: 'numeric', month: 'short', day: 'numeric'};
    return new Date(dateString).toLocaleDateString('en-US', options);
}

export const onThumbMouseEnter = (e) => {
    const childern = e.target.children[0];
    if (childern) {
        childern.style = 'filter: invert(0); color: transparent; font-size: 35px;'
    }

    // e.target.style = 'filter: invert(0); color: transparent; font-size: 35px;'
}

export const onThumbMouseLeave = (e) => {
    const childern = e.target.children[0];
    if (childern) {
        childern.style = 'filter: invert(1); color: transparent; font-size: 35px;'
    }
    // e.target.style = 'filter: invert(1); color: transparent; font-size: 35px;'
}