import {axiosCall, getAPIConfig} from "../app.config";

export const getSettingsApi = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_SETTINGS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'GET');

    return  await axiosCall(axiosConfig);
}

export const updateSettings = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_UPDATE_SETTINGS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const updateLumaCookiesApi = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_UPDATE_LUMA_COOKIES_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');

    return  await axiosCall(axiosConfig);
}


export const predictionJobsApi = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_PREDICTION_JOBS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');

    return  await axiosCall(axiosConfig);
}


export const generatedPredictionApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_GENERATED_PREDICTION_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}


export const resetPredictionJobs = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_RESTART_PREDICTION_JOBS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');

    return  await axiosCall(axiosConfig);

}



