import {
    Avatar,
    BlockStack, Button,
    Card,
    Grid, Icon, IndexTable, InlineGrid,
    InlineStack,
    MediaCard,
    Modal, ProgressBar, ResourceItem,
    ResourceList, Spinner,
    Text,
    Thumbnail,
    Badge as PolarisBadge, Popover, ActionList, Page, Layout, ChoiceList, CalloutCard, Select, TextField
} from "@shopify/polaris";


import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../lib/contextLib";
import {useLocation} from "react-router-dom";
import CustomDrawer from "./common/custom_drawer";
import {getShopifyProductImagesAPI} from "../servies/products.services";


import {createPredictionApi, generatePromptApi, getPredictionStatus} from "../servies/prediction.services";

import {
    Badge,
    Col,
    RadioTile,
    RadioTileGroup,
    Row,
    Stack,
    useMediaQuery,
    Button as RSuiteButton,
    Button as RsuiteButton, ButtonGroup, List, Dropdown, SelectPicker, Input, InputGroup, Divider, FlexboxGrid
} from "rsuite";
import * as PropTypes from "prop-types";
import {TipModal} from "./modal/TipModal";
import TosterMessage from "./common/toaster_message";
import {ImagePlaceholder} from "./create_ai_video/ImagePlaceholder";
import styles from "../styles/LeftColumn.module.css";


function SpacingBackground(props) {
    return null;
}

SpacingBackground.propTypes = {children: PropTypes.node};
export const CreateDrawer = ({
                                     productName,
                                     isImageModalOpen,
                                     setIsImageModalOpen,
                                     selectedProductID,

                                     view
                                 }) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState(false);
    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [isInline] = useMediaQuery('xl');
    const [startImageId, setStartImageId] = useState(null);
    const [endImageId, setEndImageId] = useState(null);
    const [startImageSrc, setStartImageSrc] = useState(null);
    const [endImageSrc, setEndImageSrc] = useState(null);
    const [totalSelectedImage, setTotalSelectedImage] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]);
    const [userPrompt, setUserPrompt] = useState('');
    const [openTipModal, setOpenTipModal] = useState(false);
    const [popoverActive, setPopoverActive] = useState(false);
    const [onBlankBoxClick, setOnBlankBoxClick] = useState(false);
    const [generatedPormpt, setGeneratedPrompt] = useState('');
    const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);


    const {
        isRefresh, setIsRefresh,
        shopState, setShopState,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);

    const handleSelectImage = (id, src) => {
        console.log(id)
        if (startImageId === id) {
            setStartImageId(null);
            setStartImageSrc(null);
            setStartImageSrc(endImageSrc);
            setStartImageId(endImageId);
            setEndImageId(null);
            setEndImageSrc(null);
        } else if (endImageId === id) {

            setEndImageId(null);
            setEndImageSrc(null);

        } else if (startImageId === null) {
            setStartImageSrc(src);
            setStartImageId(id);
        } else if (endImageId === null) {
            setEndImageSrc(src);
            setEndImageId(id);
        }

    };

    const getBadgeContent = (id) => {

        if (startImageId === id) return '1';
        // if (startImageId === id) return 'Start Image';
        if (endImageId === id) return '2';
        // if (endImageId === id) return 'End Image';
        return null;
    };

    const handleMakeAiVideo = () => {
        let finalPrompt = '';
        if (generatedPormpt !== '') {
            finalPrompt = generatedPormpt;
        } else if (userPrompt !== '') {
            finalPrompt = userPrompt;
        }

        const inputData = {
            'product_id': selectedProductID,
            'start_image_id': startImageId,
            'end_image_id': endImageId,
            'start_image_src': startImageSrc,
            'end_image_src': endImageSrc,
            'user_prompt': generatedPormpt
        }

        setIsCreationLoading(true);

        createPredictionApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }

            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                const bannerData = {
                    title: 'Your AI video is being created',
                    tone: 'success',
                    description: `<b>${shopState.email}</b> will get emailed when the AI video is ready for the <b>${productName}</b>`
                }
                setIsRefresh(true)
                setBannerMessage(bannerData)
                setIsImageModalOpen(false)
                setIsCreationLoading(false)

            })


    }

    const generatePrompt = () => {
        const inputData = {
            'images_url': [startImageSrc, endImageSrc]
        }
        setIsGeneratingPrompt(true)
        generatePromptApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    TosterMessage.error(`${res.message} - Try again`);
                    return;
                }
                setGeneratedPrompt(data)
                setIsGeneratingPrompt(false)

            })
            .catch(err => {
                console.log(err)

            })
            .finally(() => {
                setIsGeneratingPrompt(false)
            })
    }

    useEffect(() => {
        const inputData = {
            product_id: selectedProductID
        }
        setLoading(true)

        getShopifyProductImagesAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }
                console.log('data', data)
                const images = data.images;
                setProductImages(images);
                console.log('aiImages', images)

            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }, []);


    const onCameraMovementChange = (value) => {
        console.log({value})
        setUserPrompt(value || '')
    }

    const options = [
        /*{
            label: 'STATIC',
            value: 'static',

        },*/
        {
            disable: true,
            label: 'MOVE LEFT',
            value: 'camera move left',

        },
        {
            label: 'MOVE RIGHT',
            value: 'camera move right',

        },
        {
            label: 'MOVE UP',
            value: 'camera move up',


        },
        {
            label: 'MOVE DOWN',
            value: 'camera move down',

        },
        {
            label: 'PUSH IN',
            value: 'camera push in',

        },
        {
            label: 'PULL OUT',
            value: 'camera pull out',
        },
        /*{
            label: 'PAN LEFT',
            value: 'panLeft',
        },
        {
            label: 'PAN RIGHT',
            value: 'panRight',
        },
        {
            label: 'ORBIT LEFT (Rotate Counter Clockwise)',
            value: 'orbitLeft',
        },
        {
            label: 'ORBIT RIGHT (Rotate Clockwise)',
            value: 'orbitRight',
        },
        {
            label: 'CRANE UP (Circle Up)',
            value: 'craneUp',
        },
        {
            label: 'CRANE DOWN (Circle Down)',
            value: 'zoomOut',
        },*/

    ];

    const blinkOnGridImages = () => {
        if (startImageId !== null && endImageId !== null) return;
        setOnBlankBoxClick(true)
        const timeout = setTimeout(() => {
            setOnBlankBoxClick(false)
        }, 500)
    }

    return <>
        <TipModal
            title={'Which image work best for AI Video'}
            open={openTipModal}
            setOpen={setOpenTipModal}

        />
        <CustomDrawer
            placement={'bottom'}
            size={'calc(100% - 120px)'}
            open={isImageModalOpen}
            setOpen={setIsImageModalOpen}
            title={productName}
        >
            {loading ? <Spinner/> : null}

            <Layout>


                <Layout.Section variant="oneThird">

                    <Card>

                        {
                            startImageId === null && endImageId === null ?
                                <Text variant="headingLg" as="span" alignment={"start"} tone={"base"}>
                                    Select an image to make a video
                                </Text>
                                :
                                <>
                                    <BlockStack gap="400" align={'start'} inlineAlign={'center'}>

                                        <Grid columns={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2}}>
                                            <Grid.Cell>
                                                <div onClick={blinkOnGridImages}>
                                                    <ImagePlaceholder
                                                        image={startImageSrc}
                                                        label="Select Start Image"
                                                    />
                                                </div>
                                            </Grid.Cell>

                                            <Grid.Cell>
                                                <div onClick={blinkOnGridImages}>

                                                    <ImagePlaceholder
                                                        image={endImageSrc}
                                                        label="Select End Image"
                                                    />

                                                </div>
                                            </Grid.Cell>
                                        </Grid>


                                        <Input
                                            className={styles.textarea}
                                            placeholder="Describe what you want to see (optional)"
                                            value={generatedPormpt}
                                            as={'textarea'}
                                            rows={3}
                                            onChange={setGeneratedPrompt}


                                        />
                                        <RsuiteButton
                                            onClick={generatePrompt}
                                            className={styles.generateButton}
                                            loading={isGeneratingPrompt}
                                            disabled={generatedPormpt !== '' || startImageId === null || endImageId === null}
                                            tooltip={'Generate description for AI video'}
                                        >
                                            Generate description (optional)
                                        </RsuiteButton>
                                        <Divider
                                            style={{margin: '10px'}}

                                        />

                                        <RsuiteButton
                                            onClick={handleMakeAiVideo}
                                            className={styles.videoButton}
                                            color="green" appearance="primary"
                                            loading={isCreationLoading}
                                            disabled={startImageId === null || endImageId === null || isCreationLoading || isGeneratingPrompt}
                                        >
                                            Make AI Video
                                        </RsuiteButton>


                                    </BlockStack>

                                </>

                        }
                    </Card>

                </Layout.Section>
                <Layout.Section>

                    <Card>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))",
                                gap: "16px",
                                overflowY: "auto",
                                maxHeight: "642px",
                            }}
                        >
                            {productImages.map(({image_id, src}) => (
                                <Card>
                                    <div
                                        key={image_id}
                                        style={{
                                            position: "relative",
                                            cursor: "pointer",
                                            border:
                                                startImageId === image_id || endImageId === image_id
                                                    ? "2px solid #FBBC04"
                                                    : "none",
                                            borderRadius: "6px",
                                            overflow: "hidden",
                                        }}
                                        onClick={() => handleSelectImage(image_id, src)}
                                    >
                                        <img
                                            src={src}
                                            alt={`Thumbnail ${image_id}`}
                                            style={{
                                                width: "100%",
                                                height: "190px",
                                                objectFit: "contain",
                                                borderRadius: "6px",
                                            }}
                                        />
                                        {((startImageId === image_id || endImageId === image_id) || onBlankBoxClick) && (
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(194,212,241,0.62)", // semi-transparent overlay
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: "6px",
                                                    color: "white",
                                                    fontSize: "24px",
                                                    fontWeight: "bold",
                                                    zIndex: 1,
                                                }}
                                            >
                                                {getBadgeContent(image_id)}
                                            </div>
                                        )}
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </Card>

                </Layout.Section>

            </Layout>
        </CustomDrawer>
    </>


}