import React, {useEffect, useState} from 'react';
import {Panel, List, FlexboxGrid, Table} from 'rsuite';
import {getLumaUsageApi} from "../../servies/admin.services";
import ToasterMessage from "./toaster_message";
import {getAuthSession} from "../../servies/common.services";
import {useLocation, useNavigate} from "react-router-dom";
import {Placeholder} from 'rsuite';
import {formatDate} from "./util";

export const SubscriptionDetails = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [authData, setAuthData] = useState({});

    const [lumaUsageData, setLumaUsageData] = useState(null);

    const getLumaUsage = (params) => {
        setIsLoading(true);
        getLumaUsageApi(params)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                console.log('LumaUsageData', res);
                setLumaUsageData(data);
            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
                setLumaUsageData(null);
            })
            .finally(() => {
                console.log('finally');
                setIsLoading(false);
            })

    }


    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);

        getLumaUsage(params);


    }, [])

    if (isLoading) {
        return <Placeholder.Grid rows={5} columns={6} active/>
    }


    return (


        <Panel bordered header="Subscription Details" style={{padding: '20px', marginBottom: '20px'}}>
            {
                lumaUsageData === null ?
                    <div>
                        <h3>No Subscription Data Found</h3>
                        <p>
                            Please update the Luma Cookies from Settings (By Clicking <b>Update Luma
                            Cookies</b> Button).
                        </p>
                    </div>
                    :
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <List bordered>
                                <List.Item>Consumed: {lumaUsageData.consumed}</List.Item>
                                <List.Item>Capacity: {lumaUsageData.capacity}</List.Item>
                                <List.Item><b>Available: {lumaUsageData.available} by {formatDate(lumaUsageData?.subscription?.paid_cycle.end)}</b></List.Item>
                                <List.Item>Active: {lumaUsageData?.subscription.active ? 'Yes' : 'No'}</List.Item>
                                <List.Item>Plan: {lumaUsageData?.subscription.plan}</List.Item>
                                <List.Item>Type: {lumaUsageData?.subscription.type}</List.Item>
                            </List>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <Table data={[lumaUsageData?.subscription.free_cycle]} autoHeight bordered>
                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Free Cycle Start</Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => {
                                            return formatDate(rowData.start);
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column width={200} align="center">
                                    <Table.HeaderCell>Free Cycle End</Table.HeaderCell>
                                    <Table.Cell>
                                        {rowData => {
                                            return formatDate(rowData.end);
                                        }}
                                    </Table.Cell>
                                </Table.Column>
                                <Table.Column width={100} align="center">
                                    <Table.HeaderCell>Capacity</Table.HeaderCell>
                                    <Table.Cell dataKey="capacity"/>
                                </Table.Column>
                            </Table>
                            {lumaUsageData?.subscription.paid_cycle && (
                                <Table data={[lumaUsageData?.subscription.paid_cycle]} autoHeight bordered>
                                    <Table.Column width={200} align="center">
                                        <Table.HeaderCell>Paid Cycle Start</Table.HeaderCell>
                                        <Table.Cell>
                                            {rowData => {
                                                return formatDate(rowData.start);
                                            }}
                                        </Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={200} align="center">
                                        <Table.HeaderCell>Paid Cycle End</Table.HeaderCell>
                                        <Table.Cell>
                                            {rowData => {
                                                return formatDate(rowData.end);
                                            }}
                                        </Table.Cell>
                                    </Table.Column>
                                    <Table.Column width={100} align="center">
                                        <Table.HeaderCell>Capacity</Table.HeaderCell>
                                        <Table.Cell dataKey="capacity"/>
                                    </Table.Column>
                                </Table>)}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

            }
        </Panel>


    );
};
