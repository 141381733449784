import {
    BlockStack,
    Button,
    IndexTable,
    InlineStack,
    Text,
    Thumbnail,
    Modal,
    MediaCard,
    Card,
    Badge,
    Icon
} from "@shopify/polaris";

import {ImageAddIcon, ViewIcon} from "@shopify/polaris-icons";
import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {ViewDrawer} from "./ViewDrawer";
import {handleMouseMoveZoom, handleMouseOutZoom} from "./common/util";
import {AppContext} from "../lib/contextLib";
import ThumbFeedback from "./common/thumbFeedback";
import useEmblaCarousel from "embla-carousel-react";
import {
    usePrevNextButtons
} from '../components/EmblaCarouselArrowButtons';
import {upperCase} from "lodash";


export default function AIVideoIndexTableRow({
                                                 productID,
                                                 productName,
                                                 productStatus,
                                                 totalInventory,
                                                 totalVariants,
                                                 productImage,
                                                 productImages,
                                                 predictions,
                                                 isPredictionCreated,
                                                 selectedTab,
                                             }, index, selectedProduct) {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const [selectedProductID, setSelectedProductID] = useState(null);
    const [slideCount, setSlideCount] = useState(0);


    const {
        isRefresh, setIsRefresh,
        shopState, setShopState,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);

    const filteredPredictions = predictions.filter(prediction => prediction.state === 'completed')

    const aiVideosColRender = filteredPredictions.map((prediction, index) => {
        return (
            <div className={'embla__slide'}>
                <video
                    key={prediction?.video?.url}
                    controls
                    height={'250px'}
                >
                    <source src={prediction?.video?.url} type="video/mp4"/>
                </video>
                <br/>

                <ThumbFeedback
                    id={prediction?.id}
                    state={prediction.state}
                    CreatedAt={prediction.created_at}
                />
            </div>

        )
    })

    useEffect(() => {
        console.log('selectedTabIndex', selectedTab);
        setSlideCount(aiVideosColRender.length <= 3 ? aiVideosColRender.length : aiVideosColRender.length + 1)
    }, [])

    const statusTOTone = {
        'ACTIVE': 'success',
        'ARCHIVED': '',
        'DRAFT': 'info',
    }

    const hasVideos = predictions.some(prediction => prediction.video !== null);


    return (<>
        {isViewDrawerOpen ? <ViewDrawer
            productID={productID}
            productName={productName}
            productImage={productImage}
            productImages={productImages}
            predictions={predictions}
            isPredictionCreated={isPredictionCreated}

            isImageModalOpen={isViewDrawerOpen}
            setIsImageModalOpen={setIsViewDrawerOpen}
            selectedProductID={selectedProductID}
            setSelectedProductID={setSelectedProductID}
            view={1}

        /> : null}

        <IndexTable.Row
            id={productID}
            productName={productName}
            productImage={productImage}

            productImages={productImages}
            predictions={predictions}
            isPredictionCreated={isPredictionCreated}

            selected={selectedProduct}
            key={productID}
            position={index}
        >
            <IndexTable.Cell>
                <InlineStack align={'start'} gap={'200'}>
                    <div
                        // onMouseMove={handleMouseMoveZoom}
                        // onMouseOut={handleMouseOutZoom}
                    >
                        <Button
                            variant={"plain"}
                            onClick={() => {
                                setSelectedProductID(productID)
                                setIsViewDrawerOpen(true)
                            }}
                        >
                            <Thumbnail
                                source={productImage}
                                alt={productName}
                                size="large"
                            />
                        </Button>
                    </div>

                    <BlockStack>
                        <div style={{width: '250px', textWrap: 'wrap'}}>
                            <Button
                                variant={"plain"}
                                onClick={() => {
                                    setSelectedProductID(productID)
                                    setIsViewDrawerOpen(true)
                                }}
                            >
                                <Text variant="bodyMd" as="span" alignment={"start"} tone={"base"}>
                                    {productName}
                                </Text>
                            </Button>
                        </div>
                    </BlockStack>

                </InlineStack>
            </IndexTable.Cell>

            <IndexTable.Cell>
                <Badge tone={statusTOTone[upperCase(productStatus)]}>
                    {productStatus?.charAt(0).toUpperCase() + productStatus?.substring(1).toLowerCase()}
                </Badge>
            </IndexTable.Cell>

            <IndexTable.Cell>
                <Text variant="bodyMd"  as="span">
                    {totalInventory} in stock for {totalVariants} variants
                </Text>
            </IndexTable.Cell>

            <IndexTable.Cell>
                <Button
                    icon={ViewIcon}
                    disabled={!hasVideos}
                    accessibilityLabel={`View ${predictions.length} Videos`}
                    onClick={() => {
                        setSelectedProductID(productID)
                        setIsViewDrawerOpen(true)
                    }}
                >
                    {hasVideos ? predictions.length > 1 ? `View ${predictions.length} Videos` : "View video" : "In Progress"}
                </Button>
            </IndexTable.Cell>


        </IndexTable.Row>
    </>);

}