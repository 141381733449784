import {Form, ButtonToolbar, Button, Input, Stack, toaster} from 'rsuite';
import {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import LoginModel from "../../form_models/login_model";
import Panel from "rsuite/Panel";
import {adminLoginApi} from "../../servies/admin.services";
import ToasterMessage from "../../components/common/toaster_message";
import {useNavigate} from "react-router-dom";
import {getAuthSession, setAuthSession} from "../../servies/common.services";

export const AdminAuth = () => {
    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formRef = useRef();
    const [adminData, setAdminData] = useState({});
    const navigate = useNavigate();
    const Textarea = forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref}/>);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setIsSubmitted(true);
        setIsLoading(true)
        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        adminLoginApi(formData.name, formData.password)
            .then((res) => {
                console.log(res);

                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setAdminData(data);
                setAuthSession(data);

                ToasterMessage.success('Login Successfully');
                setIsLoading(false);
                navigate('/admin');

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsLoading(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                setIsLoading(false);
                setIsSubmitted(false);
            })
    };

    useEffect(() => {
        const authDataSession = getAuthSession();
        console.log('authDataSession', authDataSession)
        if (authDataSession !== null) {
            setAdminData(authDataSession);
            navigate('/admin');
        }

    }, []);

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{
                height: '100vh'
            }}
        >
            <Panel bordered style={{background: '#fff', width: 400}} header={<h3>Sign In</h3>}>
                <Form
                    fluid
                    ref={formRef}
                    onCheck={setFormError}
                    formValue={formData}
                    onChange={setFormData}
                    model={LoginModel}
                    disabled={isLoading}
                >
                    <Form.Group controlId="email">
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="name" type="text" autoComplete="off"/>
                        <Form.HelpText tooltip>User Name is required</Form.HelpText>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <Form.Control name="password" type="password" autoComplete="off"/>
                    </Form.Group>
                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary" onClick={handleSubmit} loading={isLoading}>Login</Button>
                            {/*<Button appearance="link" disabled={true}>Forgot password?</Button>*/}
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Panel>
        </Stack>
    );

}