import React, {useCallback, useContext, useEffect, useState} from "react";
import ToasterMessage from "../common/toaster_message";
import {changePlan} from "../../servies/shop.services";
import {AppContext} from "../../lib/contextLib";
import {getPaymentPlan} from "../../servies/paymentPlan.services";
import {Button, Col, Heading, Loader, Row} from "rsuite";
import {CustomModal} from "../common/common_modals";
import {Card, Text, InlineStack, Button as PolarisButton, BlockStack} from "@shopify/polaris";
import CustomDrawer from "../common/custom_drawer";

export const PlanModal = ({params, openModal, setOpenModal}) => {

    const [isPlanChanging, setIsPlanChanging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPaymentPlan, setCurrentPaymentPlan] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [paymentPlans, setPaymentPlans] = useState([]);


    const {

        shopState, setShopState,
    } = useContext(AppContext);

    useEffect(() => {
        setIsLoading(true)

        getPaymentPlan(params.toString())
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                const currentPlan = data.find(plan => plan.id === shopState.payment_plan_id);
                console.log('currentPlan', shopState)
                if (currentPlan) {
                    console.log(currentPlan)
                    setCurrentPaymentPlan(currentPlan);
                }
                setPaymentPlans(data);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Payment Plan');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));

    }, []);

    const onChangePlan = (plan_id) => {

        setSelectedPlan(plan_id);
        if (shopState.payment_plan_id === plan_id) {
            setIsPlanChanging(false);
            return;
        }
        setIsPlanChanging(true);

        changePlan(params.toString(), plan_id)
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                const redirect_url = data.redirect_url;
                window.open(redirect_url, '_top', '');

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Change Plan');
                console.log(reason);
            })
            .finally(() => {
                setIsPlanChanging(false);
                setSelectedPlan(null);
            });
    }


    return (
        <CustomDrawer
            title='Billing'
            open={openModal}
            setOpen={setOpenModal}
            placement={'bottom'}
        >

            <div style={{background: '#fbfbfb', padding: 2}}>
                <Heading>Payment Plans</Heading>
                {isLoading ? (
                    <Loader/>
                ) : (
                    <InlineStack distribution="fillEvenly">
                        {paymentPlans.map((plan, index) => (
                            <Col key={index} xs={24} sm={12} md={8} style={{marginTop:15}}>
                                <Card>
                                    <BlockStack vertical spacing="tight">

                                        <Text variant="headingMd" as="h3" fontWeight="bold" alignment={"center"}>
                                            <div style={{fontSize: '20px', marginBottom: '40px'}}>{plan.name}</div>
                                        </Text>


                                        <Text variant="headingMd" fontWeight="bold" alignment={"center"}>
                                            <div style={{fontSize: '50px', marginBottom: '40px'}}>${plan.price}</div>
                                        </Text>

                                        <Text variant="headingMd" fontWeight="bold" alignment={"center"}>
                                            <div style={{marginBottom: '40px'}}>{plan.description}</div>

                                        </Text>


                                        <PolarisButton
                                            variant={currentPaymentPlan.price >= plan.price ? 'secondary' : 'primary'}
                                            disabled={currentPaymentPlan.id === plan.id}
                                            loading={isPlanChanging && selectedPlan === plan.id}
                                            onClick={() => onChangePlan(plan.id)}
                                            fullWidth={false}
                                            size={"large"}

                                        >
                                            {currentPaymentPlan.id === plan.id
                                                ? 'Current Plan'
                                                : currentPaymentPlan.price > plan.price
                                                    ? 'Downgrade'
                                                    : 'Upgrade'}
                                        </PolarisButton>
                                    </BlockStack>
                                </Card>
                            </Col>
                        ))}
                    </InlineStack>
                )}
            </div>
        </CustomDrawer>
    )
}