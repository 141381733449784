import {InputGroup, Tooltip, Whisper} from "rsuite";
import HelpOutlineIcon from "@rsuite/icons/HelpOutline";
import React from "react";

export const CustomToolTip = ({children, toolText,...props}) => {
    return (

        // <ButtonToolbar>
        <InputGroup inside>
            <>
                {children}
                <Whisper
                    trigger="click"
                    placement="auto"
                    speaker={
                        <Tooltip>{toolText}</Tooltip>
                    }
                >
                    <InputGroup.Addon>
                        <HelpOutlineIcon
                            style={{
                                fontSize: 15,
                                textAlign: 'center',
                                verticalAlign: 'middle',
                            }}
                        />
                    </InputGroup.Addon>

                </Whisper>
            </>


        </InputGroup>
        // </ButtonToolbar>

    );
}