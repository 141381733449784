import axios from "axios";

const commonHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
}

export const getSERVER_URL = () => {
    if (!process.env.REACT_APP_SERVER_BASE_URL) {
        throw new Error("SERVER_BASE_URL must be set in the environment");
    }

    if (process.env.REACT_APP_SERVER_PORT) {
        return process.env.REACT_APP_SERVER_BASE_URL + ":" + process.env.REACT_APP_SERVER_PORT;
    }
    return process.env.REACT_APP_SERVER_BASE_URL;
}

export const getAPIConfig = (envKey,verificationKey,method='POST',data=null,query_params=null ) => {
    if (!process.env[envKey]) {
        throw new Error(envKey + " must be set in the environment");
    }
    let url = getSERVER_URL() + process.env[envKey];

    if (query_params) {
        url = url + "?" + query_params;
    }

    const axiosConfig = {
        url: url,
        method: method,
        headers: {'verification-key': verificationKey, ...commonHeaders},
        data
    }

    return axiosConfig;
}

export const getAPIChangePlanConfig = (verificationKey,plan_id) => {
    if (!process.env.REACT_APP_API_CHANGE_PAYMENT_PLAN_PATH) {
        throw new Error("REACT_APP_API_CHANGE_PAYMENT_PLAN_PATH must be set in the environment");
    }
    const url = getSERVER_URL() + process.env.REACT_APP_API_CHANGE_PAYMENT_PLAN_PATH + plan_id;

    const axiosConfig = {
        url: url,
        method: 'GET',
        headers: {'verification-key': verificationKey, ...commonHeaders},
    }

    return axiosConfig;
}

export const getAPIDeletePlanConfig = (verificationKey,plan_id) => {
    if (!process.env.REACT_APP_API_DELETE_PAYMENT_PLAN_PATH) {
        throw new Error("REACT_APP_API_DELETE_PAYMENT_PLAN_PATH must be set in the environment");
    }
    const url = getSERVER_URL() + process.env.REACT_APP_API_DELETE_PAYMENT_PLAN_PATH + plan_id;

    const axiosConfig = {
        url: url,
        method: 'DELETE',
        headers: {'verification-key': verificationKey, ...commonHeaders},
    }

    return axiosConfig;
}

export const axiosCall = async (axiosConfig) => {
    let data = {data: [], error: '', message: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
        data.message = responseData.message;
    } else {
        data.data = null;
        data.error = responseData.message;
        data.message = responseData.message;
    }

    return data;
}
