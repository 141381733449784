import {axiosCall, getAPIConfig, getAPIDeletePlanConfig} from "../app.config";

export const getPaymentPlan = async (params,isAll=false) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'GET',null,`is_all=${isAll}`);

    return  await axiosCall(axiosConfig);
}

export const updatePaymentPlan = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_UPDATE_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'PATCH',inputData);

    return  await axiosCall(axiosConfig);
}

export const createPaymentPlan = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_CREATE_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const deletePaymentPlan = async (params,id) => {
    const verificationKey = btoa(params).toString('base64');
    const axiosConfig = getAPIDeletePlanConfig(verificationKey, id);

    return  await axiosCall(axiosConfig);
}

export const changeDefaultActivePaymentPlan = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_CHANGE_DEFAULT_ACTIVE_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}