import {IconButton, Placeholder} from "rsuite";
import {Icon as RIcon} from "@rsuite/icons";
import {BlockStack, InlineStack, Text, Button} from "@shopify/polaris";
import {formatDate, onThumbMouseEnter, onThumbMouseLeave} from "./util";
import React, {useEffect, useState} from "react";
import {getPredictionFeedbackApi, sendFeedbackApi} from "../../servies/prediction.services";
import {ReactComponent as thumbsUpSvg} from "../../assets/thumbs_up.svg";
import {ReactComponent as thumbsDownSvg} from "../../assets/thumbs_down.svg";
import {ReactComponent as thumbsUpActiveSvg} from "../../assets/thumb_up_active.svg";
import {ReactComponent as thumbsDownActiveSvg} from "../../assets/thumb_down_active.svg";

import ToasterMessage from "./toaster_message";
import {useLocation} from "react-router-dom";
import {ThumbsDownIcon, ThumbsUpIcon} from "@shopify/polaris-icons";

export default function ThumbFeedback({
                                          id,
                                          state,
                                          CreatedAt,
                                      }) {

    const {search} = useLocation();
    const params = new URLSearchParams(search);

    const [isThumbsUpSending, setIsThumbsUpSending] = useState(false);
    const [isThumbsDownSending, setIsThumbsDownSending] = useState(false);
    const [previousFeedback, setPreviousFeedback] = useState(null);
    const [isPreviousFeedbackLoading, setIsPreviousFeedbackLoading] = useState(false);
    const [mouseEnterThumbsUpTone, setMouseEnterThumbsUpTone] = useState(false);
    const [mouseEnterThumbsDownTone, setMouseEnterThumbsDownTone] = useState(false);

    const thumbsStyle = {
        fontSize: 35,
        color: 'transparent',
        filter: 'invert(1)'
    }

    const getPredictionFeedback = () => {
        const inputData = {
            prediction_id: id
        }
        setIsPreviousFeedbackLoading(true);
        getPredictionFeedbackApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                if (data === null) {
                    // ToasterMessage.error(res.error);
                    return;
                }

                setPreviousFeedback(data);

                console.log('PreviousFeedback data', data)

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                setIsPreviousFeedbackLoading(false);
            })
    }

    const sendFeedback = (feedback) => {
        const inputData = {
            feedback: {
                prediction_id: id,
                is_thumbs_up: feedback === 'thumbs-up',
                description: feedback === 'thumbs-up' ? 'thumbs-up' : 'thumbs-down'
            }
        }


        if (feedback === 'thumbs-up') {
            setIsThumbsUpSending(true);
        }

        if (feedback === 'thumbs-down') {
            setIsThumbsDownSending(true);
        }

        sendFeedbackApi(params.toString(), inputData)
            .then(res => {
                    const data = res.data;
                    if (data === null) {
                        ToasterMessage.error(res.error);
                        return;
                    }
                    // ToasterMessage.success(res.message);

                    setPreviousFeedback(inputData.feedback)
                }
            )
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                setIsThumbsUpSending(false);
                setIsThumbsDownSending(false);
            })
    }

    useEffect(() => {
        // getProductPrediction(productId)
        getPredictionFeedback();

    }, []);

    return (
        <>
            {

                isPreviousFeedbackLoading ?
                    <Placeholder.Graph active width={80} height={40}/>
                    :
                    previousFeedback !== null ?
                        <div>
                            {/*<IconButton
                                disabled={true}
                                size={'sm'}
                                icon={
                                    <RIcon
                                        as={previousFeedback.is_thumbs_up ? thumbsUpActiveSvg : thumbsDownActiveSvg}
                                        // style={thumbsStyle}
                                        style={{
                                            fontSize: 35
                                        }}
                                        color={'transparent'}
                                    />
                                }
                                onClick={() => sendFeedback('thumbs-up')}
                            />*/}
                            <Button
                                icon={previousFeedback.is_thumbs_up ? ThumbsUpIcon : ThumbsDownIcon}
                                accessibilityLabel="Thumbs Up"
                                size={'large'}
                                tone={previousFeedback.is_thumbs_up ? 'success' : 'critical'}
                                variant={'primary'}
                                onClick={() => null}
                            />
                        </div>
                        :
                        state !== 'completed' ? null :
                            // <div>
                            <InlineStack gap={'200'}>
                                <InlineStack align={'center'} gap={'200'}>
                                    <div
                                        onMouseEnter={() => setMouseEnterThumbsUpTone(true)}
                                        onMouseLeave={() => setMouseEnterThumbsUpTone(false)}
                                    >
                                        <Button
                                            icon={ThumbsUpIcon}
                                            accessibilityLabel="Thumbs Up"
                                            size={'large'}
                                            tone={mouseEnterThumbsUpTone ? 'success' : ''}
                                            variant={mouseEnterThumbsUpTone ? 'primary' : 'secondary'}
                                            loading={isThumbsUpSending}
                                            onClick={() => sendFeedback('thumbs-up')}

                                        />
                                    </div>
                                    <div
                                        onMouseEnter={() => setMouseEnterThumbsDownTone(true)}
                                        onMouseLeave={() => setMouseEnterThumbsDownTone(false)}
                                    >
                                        <Button
                                            icon={ThumbsDownIcon}
                                            accessibilityLabel="Thumbs Down"
                                            tone={mouseEnterThumbsDownTone ? 'critical' : ''}
                                            variant={mouseEnterThumbsDownTone ? 'primary' : 'secondary'}
                                            size={'large'}
                                            loading={isThumbsDownSending}
                                            onClick={() => sendFeedback('thumbs-down')}
                                        />
                                    </div>
                                </InlineStack>
                                {/*<IconButton
                                    loading={isThumbsUpSending}
                                    disabled={isThumbsDownSending || isThumbsUpSending || previousFeedback !== null}
                                    className={'.thumbs-up'}
                                    size={'sm'}
                                    onMouseEnter={onThumbMouseEnter}
                                    onMouseLeave={onThumbMouseLeave}
                                    icon={
                                        <RIcon
                                            as={thumbsUpSvg}
                                            style={thumbsStyle}
                                        />
                                    }
                                    onClick={() => sendFeedback('thumbs-up')}
                                />


                                <IconButton
                                    loading={isThumbsDownSending}
                                    disabled={isThumbsDownSending || isThumbsUpSending || previousFeedback !== null}
                                    size={'sm'}
                                    onMouseEnter={onThumbMouseEnter}
                                    onMouseLeave={onThumbMouseLeave}
                                    icon={
                                        <RIcon
                                            as={thumbsDownSvg}
                                            style={thumbsStyle}

                                        />
                                    }
                                    onClick={() => sendFeedback('thumbs-down')}
                                />*/}
                            </InlineStack>
                // </div>

            }
            <div>
                {/*{formatDate(CreatedAt)}*/}
            </div>
        </>
    )
}