import React, {Fragment, useCallback, useContext, useState} from 'react';
import {Bleed, Card, InlineStack, Layout, Page, Tabs, Text, SkeletonDisplayText} from '@shopify/polaris';

import '../static/chat.css';
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {AppContext} from "../lib/contextLib";
import {getShopData, getShopStats, updateShopData} from "../servies/shop.services";
import {Placeholder} from "./common/util";
import {Button as RsuiteButton} from "rsuite";
import {PlanModal} from "./modal/PlanModal";


export default function TopSection() {
    const {search} = useLocation();
    const [loading, setLoading] = useState(false);
    const params = new URLSearchParams(search);
    const [isStatsLoading, setIsStatsLoading] = useState(false);
    // const [openPlanModal, setOpenPlanModal] = useState(false);

    const {
        shopState, setShopState,
        isRefresh, setIsRefresh,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);

    useEffect(() => {

        setIsStatsLoading(true)
        getShopStats(params.toString())
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('shopStats', data);
                    // redirect('/not-auth');
                    return;
                }
                console.log('shopStats data', data)
                setShopState(data);

            })
            .catch((e) => {
                console.log(e);
                // redirect('/not-auth');
            })
            .finally(() => setIsStatsLoading(false));

        setIsRefresh(false);

    }, [isRefresh]);

    return (
        <>
            <Layout.Section variant={'fullWidth'}>
                <Card sectioned>
                    <InlineStack distribution="equalSpacing">
                        <Placeholder>
                            <Text as={''} variant={'bodyMd'}>Videos created</Text>
                            {
                                isStatsLoading ? <SkeletonDisplayText size="small"/> :
                                    <Text as={'p'} variation="strong">{shopState.total_videos_created}</Text>
                            }
                        </Placeholder>
                        <Placeholder>
                            <Text as={''} variant={'bodyMd'}>Products With Videos</Text>
                            {
                                isStatsLoading ? <SkeletonDisplayText size="small"/> :
                                    <Text as={'p'} variation="strong">{shopState.total_products_generated}</Text>
                            }
                        </Placeholder>
                        <Placeholder showBorder>
                            <Text as={''} variant={'bodyMd'}>
                                Videos left until {isStatsLoading ? '' : <b>{shopState.next_month_name}</b>} 1st

                            </Text>
                            {
                                isStatsLoading ? <SkeletonDisplayText size="small"/> :
                                    <Text as={'p'} variation="strong">{shopState.predictions_left}
                                        {shopState.predictions_left <= 3 ?
                                            <RsuiteButton
                                                appearance={'link'}
                                                onClick={(e) => {
                                                    setOpenPlanModal(true)
                                                }}
                                            >
                                                Upgrade for more
                                            </RsuiteButton>
                                            : null}
                                    </Text>
                            }

                        </Placeholder>
                    </InlineStack>
                </Card>
            </Layout.Section>
        </>
    )
}