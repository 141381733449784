import {axiosCall, getAPIChangePlanConfig, getAPIConfig} from "../app.config";
import axios from "axios";

export const getProductsAPI = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PRODUCTS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}


export const getShopifyProductsAPI = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SHOPIFY_PRODUCTS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const getGeneratedProductsAPI = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_GENERATED_PRODUCTS_PATH'
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const getShopifyProductImagesAPI = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SHOPIFY_PRODUCTS_IMAGES_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}