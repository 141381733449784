import React from 'react';
import styles from '../../styles/ImagePlaceholder.module.css';

export const ImagePlaceholder = ({ image, label }) => {
    return (
        <div className={styles.container}>
            {image ? (
                <img src={image} alt={label} className={styles.image} />
            ) : (
                <span className={styles.placeholder}>{label}</span>
            )}
        </div>
    );
};